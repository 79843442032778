.flex-container {
    display: flex;
    flex-wrap: wrap;
    background-color: black;
    justify-content: space-between;
  }
  
  .flex-container > div:first-of-type {
    background-color:#6C6F70;
    width: 80px;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    /* border: 1px solid white; */
  }
  .flex-container > div:last-of-type {
    background-color:#6C6F70;
    width: 80px;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    /* border: 1px solid white; */
  }

  #optimusLogo {
    background-color: #DE3831;
    width: 110px;
    text-align: center;
    line-height: 63px;
    font-size: 24px;
    border-right: 1px solid white;
  }

  .setting-img {
    padding-top: 5px;
    padding-bottom: 5px;

  }

  .page-title {
    color: white;
    margin-right: 45%;
    margin-top: 4px;
    position: relative;
  }

  .current-user {
    color: white;
    margin-top: 20px;
    font-size: small;
    margin-left: 9%;
    position: relative;
    left: 1px;
  }

  .setting-icon {
    color: white;
    font-size: 34px;
    margin-top: 12px;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right:19px;
  }

  #header-row {
    width: 101%;
  }

  #setting-col{
    padding-right: 0% !important;
    /* display: flex;
    justify-content: flex-end; */
  }

  #userName {
    text-align: end;
    align-self: center;
  }
  
  .triangleDown {
    width: 0;
    height: 0;
    border-top: 12px solid #6C6F70;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: relative;
    top: 102%;
    right: -6%;
  }

  .triangleDown {
    width: 0;
    height: 0;
    border-top: 12px solid #DE3831;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: relative;
    top: 102%;
    right: -6%;
  }

  .triangleDownHiddeden {
    display: none;
  }

  #logoOptimus {
    height: 30px;
    margin-left: 0px;
    width: 90px;
    position: relative;
    bottom: 4px;
    margin-right: 5px;
  }

  .settingDiv {
    border-left: 1px solid white;
    background-color:#6C6F70;
    width: 83px;
    text-align: center;
    height: 63px;
    font-size: 30px;
  }

  #col-heading {
    position: relative;
    left: 53px;
  }

  #header-container {
    background-color: black;
    margin: 0;
    padding: 0;
    max-width: 100% !important;
  }

  @media (max-width: 630px) {  
    .collapseDiv {
      text-align: center;
      width: 70.5%;
      background-color: black;
      color: white;
      position: relative;
      left: 17%;
      border-left: 1px solid white;
    }
    .settingDiv {
      display: none !important;
    }
    .page-title {
      margin-right: 0%;
    }
    .current-user {
      color: white;
      margin-top: 20px;
      font-size: small;
      margin-left: 0%;
      position: relative;
      left: 25px;
    }
    .hamBurger {
      display: block;
      cursor: pointer;
      border-left: 1px solid white;
    }
  }

  @media (min-width: 630px) and (max-width: 768px) {
    .hamBurger {
      display: none;
    }
    .page-title {
      margin-right: 0%;
    }
  }
   
  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  
    .page-title {
      color: white;
      margin-right: 10%;
      margin-top: 4px;
    }
    .hamBurger {
      display: none;
    }
  }
   
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 1082px) { 
    .page-title {
      color: white;
      margin-right: 27%;
      margin-top: 4px;
    }
    .hamBurger {
      display: none;
    }
  }
   
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
    .page-title {
      color: white;
      margin-right: 35%;
      margin-top: 4px;
      width: 260px;
    }
    .hamBurger {
      display: none;
    }
  }
