.data {
    border: 1px solid brown;
    border-radius: 5px;
}
.pagination {
    display: flex;
}
.total-records {
    flex: 1;
    /* flex-grow: 1; */
    padding: 5px;
    font-weight: bold;
}
.total-records:first-child {
    margin-right: 20px;
}
.render-pages {
    flex: 1;
    text-align-last: right;
    /* float: right;
    margin-right: 5px; */
}
.pages {
    /* border: 1px solid blue; */
}
.selected-page {
    font-weight: bold;
    color: black;
}

.render-pages button {
    background-color: #8a8d8b; /* Green */
    border: none;
    border-radius: 5px;
    color: black;
    /* padding: 15px 32px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.pages button {
    background-color: #a4c4a5; /* Green */
    border: none;
    color: black;
    /* padding: 15px 32px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}
