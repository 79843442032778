.moveRight {
    float: right;
    display: flex;
    height: 33px;
}

.moveRightButton {
    width: inherit;
    float: right;
}
.searchIcon {
    float: right;
    padding: 6px 6px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
}
.moveRight input[type=text] {
    border: 1px solid #cccc !important;
    padding: 6px;
    font-size: 17px;
    border: none;
}
.move-right {
    justify-content: space-between;
}

#backIcon {
    cursor: pointer;
}

.align-dropdown {
    text-align: right;
    width: inherit;
}

.tab-content {
    border-right: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    -webkit-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    -moz-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
}

.container {
    padding: 1%;
}

#dropdown-variants-secondary {
    height: 34px;
    color: black;
    background-color: white;
    font-weight: 600;
    text-align: initial;
    padding-top: 2%;
    border: 1px solid #ced4da;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    margin-top: 9px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
}

#controlled-tab-example-tab-home {
    display: none !important;
}

@media (min-width: 1200px) {
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1260px !important;
}
}

.alignItems {
    text-align: end;
}

.cancelButton {
    width: 55%;
}

@media (min-width: 576px) {
.col-sm-2 {
    flex: 0 0 10.666667%;
    max-width: 16.666667%;
}
}