.support-ticket-card {
    border-radius: 4px;
    border-top-left-radius: 0;
    border: none;
    height: 460px;
    overflow-y: auto;
}

tbody tr:hover {
    background-color: #eeefee;
    cursor: pointer;
}

.inbox-tab-container {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    position: absolute;
    border-top: 1px solid rgb(211, 211, 211);
    border-left: 1px solid rgb(211, 211, 211);
    border-right: 1px solid rgb(211, 211, 211);
    top: -8.4%;
    width: 22%;
    left: -0.1%;
    text-align: center;
    font-weight: 600;
    background: #ffff;
    display: flex;
}

.inbox-text {
    display: flex;
    margin: 0 auto;
    padding-top: 3%;
}

.inbox-count {
    background: rgb(50, 205, 140);
    border-radius: 4px;
    color: #ffff;
    width: 25px;
    font-size: 12px;
}

.top-search-container {
    position: relative;
}

.top-dropdown-container select {
    width: 100%;
    height: 35px;
    float: right;
    padding-left: 3%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    color: rgb(110, 110, 110);
    border-radius: 5px;
    cursor: pointer;
}

.top-date-container input {
    width: 100%;
    height: 35px;
    float: right;
    padding-left: 3%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    color: rgb(110, 110, 110);
    border-radius: 5px;
}

.top-dropdown-container select:focus {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    color: rgb(110, 110, 110);
}

.search-icon {
    position: absolute;
    color: rgb(211, 211, 211);
    left: 43%;
    top: 22%;
}

/* tr {
      font-size: 14px;
      font-weight: 500;
  }
  
  td {
      font-size: 12px;
  } */

.table-header-container {
    box-shadow: 0px 1px 10px -5px rgb(0 0 0 / 75%);
    height: 30px;
}

.sub-tickets-td {
    width: 37%;
}

.sub-tickets-column {
    display: flex;
    justify-content: space-between;
}

.sub-tickets-column .left {
    width: 100%;
}

.from-column-td {
    width: 29%;
}

.from-column {
    display: flex;
    justify-content: space-between;
}

.from-column .left {
    border-radius: 50%;
    height: 30px;
    width: 10%;
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    padding-top: 1%;
    border: none;
}

.from-column .right {
    width: 85%;
    text-align: left;
}

.action-container span {
    background: rgb(108, 111, 112);
    color: #ffff;
    border-radius: 50%;
}

.table thead th {
    border-top: none !important;
    border-bottom: none !important;
}

.table tbody tr:first-child td {
    border-top: none !important;
}

#popover-list > li {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    height: 30px;
}

#popover-list > li:hover {
    background-color: #eeefee;
}

#popover-list {
    margin-bottom: 0;
}

.state-td {
    width: 15%;
}

.state-column {
    display: flex;
}

.state-column .left {
    width: 10%;
    padding-top: 3%;
    justify-content: space-between;
}

.left-inner-div {
    width: 10px;
    height: 10px;
    border-radius: 2px;
}

.send-date-td {
    width: 20%;
}

.state-column .right {
    width: 90%;
    padding-left: 3%;
}

.subject-link:hover {
    background: rgb(226, 226, 226);
}

.send-date-column {
    display: flex;
}

.action-td {
    width: 5%;
}

#search-support-ticket-input {
    width: 60%;
    height: 35px;
    float: right;
    padding-left: 3%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    color: rgb(110, 110, 110);
}

.searchIcon {
    position: absolute;
    padding: 3px 4px;
    padding-top: 1%;
    height: 32.5px;
    background: #ddd;
    font-size: 14px;
    border: none;
    right: 40.6%;
    top: 3%;
}

.position-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
