.button-component {
    font-size: 1em;
    width: inherit !important;
    padding: 0.25em 1em;
    border-radius: 3px;
    color: black;
    border: 2px solid black;
    background-color: white;
    padding: 4px !important;
}

.btn {
    height: 34px !important;
    padding-top: 3px !important;
}