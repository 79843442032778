.form-control-label {
    color: rgb(108, 111, 112);
    font-size: 14px;
}
.form-action-button-container {
    display: flex;
    justify-content: space-between;
}

.form-action-button-container-left {
    width: 45%;
}

.form-action-button-container-left * {
    width: 100%;
}

.form-action-button-container-right {
    width: 50%;
}

.form-action-button-container-right * {
    width: 100%;
}

select {
    width: 100%;
    border: 1px solid #ced4da;
    height: 35px;
    border-radius: 4px;
}

#text-editor-container {
    width: 98%;
}

.file-upload {
    display: none;
}

.clear-icon {
    padding-top: 5px;
}

.save-button {
    margin-left: auto;
    margin-right: 20px;
}
