.ToolTip {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 180px;
    height: 100%;
    background-color: #fff;
    position: relative;
    box-shadow: 0px 3px 4px 0px #ebebeb;
    border-radius: 3px;
    flex-direction: column;
    border: 1px solid #C3C8C8;
    padding: 4% 0% 1% 0%;
    border-left: 25px solid #C3C8C8;
    font-size: 15px;
    margin-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    -moz-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    margin-top: 5px;
    z-index: 2000;
  }

  .ToolTip > div {
    width: 100%;
    text-align: left;
    height: 30px;
    padding-left: 8%;
  }
  /* .ToolTip::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 15px;
    height: 20px;
    width: 20px;
    background-color: white;
    transform: rotate(45deg);
  } */

  hr {
    margin-top: 3px !important;
    margin-bottom: 8px !important;
    width: 100%;
    background-color: #D2D6D6;
  }

img {
    height: 15px;
    margin-right: 10px;
}

#assignApps {
  position: relative;
  bottom: 6px;
  padding-left: 6px;
}

.ToolTip > div:hover {
  background-color: whitesmoke;
}

#hrLine {
  margin-top: 3px !important;
  margin-bottom: 8px !important;
  width: 100%;
  background-color: #D2D6D6;
  width: 87%;
}
