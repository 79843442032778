.input-radio {
    width: 55px;
    height: 1rem;
}

.input-radio-after {
    width: 61px;
    margin-left: 11px;
    height: 1rem;
}

#occuranceModal {
    max-width: 555px !important;
}

#repeat-form {
    position: relative;
    left: 45px;
}