.weekDays-selector input {
    display: none!important;
  }
  
  .weekDays-selector input[type=checkbox] + label {
    display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 40px;
    width: 30px;
    margin-right: 10px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
    outline: 0;
  }
  
  .weekDays-selector input[type=checkbox]:checked + label {
    background: black;
    color: #ffffff;
  }