.item {
    padding: 5px 25px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 1px solid #000;
}

.file,
.folder,
.heading {
    display: flex;
    justify-content: space-between;
}

.file span,
.folder span,
.heading span {
    flex-basis: 20%;
}

.backContainer {
    margin-bottom: 10px;
}

.icons {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
}

.file {

}

.folder {
    
}

.name {
    min-width: 60%;
}

.modified {
    min-width: 25%;
}

.download {
    
}

.heading {
    font-weight: bold;
    width: 100%;
}

.container {
    max-height: 75vh;
    overflow: auto;
    padding-right: 45px;
}