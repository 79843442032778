.side-nav-container {
    display: flex;
    flex-direction: column;
    color: white;
    cursor: pointer;
    z-index: 9999;
}

.main-nav-container {
    display: flex;
    flex-direction: row;
    color: white;
    cursor: pointer;
    z-index: 9999;
    justify-content: center;
    margin-top: 18%;
}

.main-nav-container > div {
    background-color: 	#6C6F70;
    width: 120px;
    text-align: center;
    line-height: 75px;
    border: 1px solid white;  
  }

  .side-nav-container > div {
    background-color: #6C6F70;
    width: 110px;
    text-align: center;
    line-height: 50px;
    border: 1px solid white;
    border-left: none;
    height: 110px;
}

.nav-content {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    transition: width 1s, height 1s;
    color: #6C6F70;
}

.nav-content:hover {
    width: 135px;
    height: 100px;
    -webkit-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    -moz-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
    background-color: #DE3831;
    color: #DE3831;
    height: 110px;
}

.nav-content-on-click {
        width: 135px;
        height: 100px;
        -webkit-box-shadow: 7px 6px 3px 0px rgb(182, 207, 195);
        -moz-box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
        box-shadow: 7px 6px 3px 0px rgba(224,226,225,1);
        background-color: #DE3831;
        color: #DE3831;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        transition: width 1s, height 1s;
        height: 110px;
}

.nav-items {
    display: flex;
    flex-direction: column;
}

.nav-content:before, .nav-content:after {
	border-bottom-color: #fff;  /* arrow color */

	/* positioning */
	position: absolute;
	top: -19px;
	left: 255px;
	z-index: 2;
  }

.span-item {
    height: 20px;
    margin-top: 23px;
    color: white;
}

.icon-style {
    font-size: 30px;
    color: white;
}

.name {
    color: white;
    font-size: 14px;
}

.triangle {
	width: 0;
	height: 0;
    border-top: 10px solid transparent;
    border-left: 15px solid;
    border-bottom: 10px solid transparent;
    position: relative;
    left: 100%;
    bottom: 49px;
}

.triangleHover {
    border-left: 10px solid #DE3831;
}


@media (max-width: 630px) {  
    .side-nav-container {
        display: none !important;
    }
}
