.search-select {
    width: 100%;
    font-weight: 400;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding-left: 7%;
}

.search-select::after {
    display: inline-block;
    position: absolute;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    margin-top: 9px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    left: 83%;
}

.set-box {
    max-height: 238px;
    overflow-y: auto;
}

.serach-box {
    max-height: 300px;
    max-width: 270px;
}