.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: black;
    color: whitesmoke;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.3;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: #6C6F70;
    color: white;
}

.close:hover {
    color: white;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: none;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

