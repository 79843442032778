#fill-button-col {
    margin-left: 0;
    padding-right: 0;
    max-width: unset;
}

#top-bar {
    justify-content: space-between;
}

.right-holder {
    display: flex;
}
.right-holder-report {
    display: flex;
    margin-right: 10px;
}

#text-editor-form1 {
    border-radius: 9px;
    font-size: 14px;
}

.align-button {
    text-align: end;
}

.assign-title-center {
    text-align: center;
}

#avatar {
    object-fit: cover;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 0px;
}

.no-border {
    border: 0px;
    font-size: 14px;
}

#list-top {
    border: 0px;
}

.set-padding {
    padding-top: 5px;
}

.mat-icons-pos {
    float: right;
}

.align-last {
    text-align: end;
}

.dropdown-font {
    font-size: 14px;
}

button {
    font-size: 14px;
}

.form-control {
    font-size: 14px;
}

#comments-tab {
    box-shadow: none;
}

.set-nav {
    position: relative;
    top: 28px;
    right: 1px;
    border: none;
}

#left-tabs-example-tab-first {
    border: none;
}

.button-move-right {
    float: right;
}

#dropdown-variants-secondary {
    font-size: 14px;
}

.font-set-size {
    font-size: 14px;
}
.adjust-space {
    margin-top: 0;
}

.project-data {
    font-size: 12px !important;
    padding-left: 0.75rem;
}
#project-data2 {
    margin-top: 20px !important;
}

.set-input-width {
    width: 98%;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    height: 15px;
}

.file-input {
    width: 0;
    position: relative;
    color: transparent;
    opacity: 0;
}

@media (max-width: 768px) {
    .adjust-space {
        margin-top: 4rem;
    }
}

@media (min-width: 576px) {
    button {
        font-size: 10px;
    }
}

#popover-list > li {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    height: 30px;
}

#popover-list > li:hover {
    background-color: #eeefee;
}

#popover-list {
    margin-bottom: 0;
}

.cancel-btn-align {
    max-width: unset;
}

.save-btn-align {
    flex-basis: 10%;
    max-width: unset;
    margin-right: 15px;
}

.edit .save-btn-align {
    margin-left: 0;
}

.edit-form .input-holder .span-item {
    top: 2px;
}

.center {
    text-align: center;
}

.no-padding {
    padding-left: 0;
}

.input-holder input,
#workitem-input-task {
    width: 90%;
}

#group-id {
    max-width: 100%;
    margin-left: 1px;
}
.table-user {
    height: 100px;
    margin-top: 10px;
}
.table-allocation {
    /* height:100px; */
    margin-top: 10px;
}
.search-bar {
    margin-left: 20px;
}
.pl-1 {
    margin-bottom: 10px;
}

.loader-list {
    text-align: center;
    font-size: 41px;
}
