.moveRight {
    float: right;
    display: flex;
    height: 33px;
    padding-right: 16px;
}

.moveRightButton {
    width: inherit;
    float: right;
}
.searchIcon {
    float: right;
    padding: 6px 6px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
}
.moveRight input[type=text] {
    border: 1px solid #cccc !important;
    padding: 6px;
    font-size: 17px;
    border: none;
}