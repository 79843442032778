.table-responsive{
    padding: 2%;
    cursor: pointer;
    max-height: 23rem;
}

.tableClickable {
    vertical-align: middle !important;
}

.tableClickable:hover {
    text-decoration: underline;
    vertical-align: middle;
}

#tableHeading {
    -webkit-box-shadow: 0px 1px 10px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 10px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 10px -5px rgba(0,0,0,0.75);
}

.textAlign {
    text-align: right;
    padding-right: 2% !important;
}

.textAlignHeader {
    text-align: right;
    padding-right: 3% !important;
}

.indexRow {
    width: 8% !important;
}

#applicationUrl {
    display: block !important;
    color: black;
}
